/* Mixins START */
@mixin font($fs: 14px, $fw: 400, $c: #000, $lh: null, $ls: null) {
  font-size: $fs;
  font-weight: $fw;
  color: $c;

  @if $lh {
    line-height: $lh;
  }

  @if $ls {
    letter-spacing: $ls;
  }
}

@mixin size($w: auto, $h: auto) {
  width: $w;
  height: $h;
}

@mixin flex-column($gap: null) {
  display: flex;
  flex-direction: column;

  @if $gap {
    gap: $gap;
  }
}

@mixin flex-row($gap: null, $justify: null, $align: null) {
  display: flex;
  flex-direction: row;

  @if $gap {
    gap: $gap;
  }

  @if $justify {
      justify-content: $justify;
  }

  @if $align {
      align-items: $align;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-vertical {
  display: flex;
  align-items: center;
}

@mixin text-ellipsis($w: 100%) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: $w;
}

@mixin active() {
  &:active:not(:disabled) {
    @content;
  }
}

@mixin hover() {
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    @content;
  }
}

@mixin focus() {
  &:focus:not(:hover, :disabled) {
    @content;
  }
}
/* Mixins END */

:root {
  --color-white: #fff;
  --color-black: #000;
  --color-primary: #FF8A00;
  --color-primary-darker: hsl(from var(--color-primary) h s calc(l - 5));

  --color-warning: var(--color-primary);
  --color-success: #3B9A00;
  --color-danger: #F50000;

  --border-radius-md: 10px;
  --border-radius-sm: 15px;
  --border-radius-lg: 20px;

  --padding-card: 20px;
}

html {
  height: 100%;
  min-width: 900px;
}

body {
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  letter-spacing: 0.5px;
  color: #222222;
}

body * {
  outline: none !important;
}

$titles: (
  h1: 24px,
  h2: 20px,
  h3: 16px,
  h4: 12px,
);

@each $h, $size in $titles {
  .title-#{$h} {
    font-size: $size;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.marign-right-5 {
  margin-right: 10px;
}

.margin-right-10 {
  margin-right: 10px;
}

.padding-none {
  padding: 0;
}

.table-row-clicking:hover {
  background: rgba(0, 0, 0, 0.05) !important;
  cursor: pointer;
}

.view-container {
  box-sizing: border-box;
  height: 100%;
  padding: 0;

  &.view-container--ui {
    //background: #FFF1E1 !important;
  }
}
.view-container .view-content {
  padding: 0;
  height: 100%;
  box-shadow: none;
}
.navbar + .view-container {
  height: calc(100% - 100px);
  overflow: auto;
  overflow-x: hidden;
  padding: 15px;

  &.view-container--ui {
    height: calc(100% - 90px); // fix bottom spacing only for new UI
  }
}
.navbar + .view-container .view-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1460px;
}
// .view-container .view-content .table-wrapper {
//   height: calc(100% - 110px);
//   box-shadow: 0 0 10px rgba(102, 116, 143, 0.08);
//   background: white;
// }
.view-container .view-content .view-wrapper {
  height: calc(100% - 51px);
}
.view-content .card-header {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 0;
  color: white;
  padding: 20px 40px;
}
.navbar + .view-container .view-content .card-header {
  color: #7c8189;
}

.modal-title {
  color: #7c8189;
  text-transform: capitalize;
  font-weight: 600;
  white-space: normal;
  word-break: break-word;
}
.form-control:focus {
  box-shadow: none;
  border-color: #8e8d8a;
}

.view-name-header {
  margin-bottom: 15px;
  .btn {
    min-height: 38px;
  }
}
.view-name-header h4 {
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  color: #7c8189;
}

.table .material-icons {
  font-size: 13px;
}

.bg-white {
  background: white;
}

.pull-right {
  float: right;
}
.bg-light {
  background: white !important;
}
.table {
  color: #212529;
  border: none;
}
.table thead tr th {
  border: none;
  font-size: 12px;
  color: white;
  padding: 10px;
}
.table tbody td {
  border: none;
  padding: 10px;
  font-size: 13px;
}
.table th,
.table td {
  padding: 0.45rem;
}

.table tbody tr:last-of-type {
  border: none;
}
.form-control {
  border: none;
  border: 0.25px solid rgba(151, 151, 151, 0.2);
  background: white;
  border-radius: 0;
  font-size: 16px;
  padding: 10px;
  min-height: 44px;
  &:focus {
    border: 0.25px solid rgba(151, 151, 151, 0.2);
  }
}
.form-control::placeholder {
  font-size: 13px;
}
.form-control:-ms-input-placeholder {
  font-size: 13px;
}
.card-body {
  background: white;
  padding: 20px 40px;
}
.card-footer {
  border: none;
}
.card-header {
  border-bottom: 0.25px solid rgba(151, 151, 151, 0.5);
}
.alone-modal-post {
  box-shadow: 0px 0px 10px rgba(102, 116, 143, 0.08);
}
.card {
  box-shadow: 0px 0px 10px rgba(102, 116, 143, 0.08);
  border: none;
  border-radius: 0;
  .card-header {
    background: white;
    color: #222222;
  }
  .card-body {
    padding: 20px 40px;
    .col-sm-12 {
      padding: 0;
    }
  }
  .card-footer {
    background: white;
    border: 0;
    border-top: 0.25px solid rgba(151, 151, 151, 0.2);
  }
}
.form-control::-ms-input-placeholder {
  font-size: 13px;
}
textarea.form-control {
  height: auto !important;
}
.card-body .form-control {
  height: unset;
}
.login-page .form-control {
  height: unset !important;
}
.btn {
  font-size: 13px;
}

.table-wrapper .table {
  overflow: hidden;
  box-sizing: border-box;
  margin: 0;
}
.table-wrapper {
  table thead tr {
    background: #66748f;
    color: white;
  }

  /* Flex Table */
  table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  table thead,
  table tbody {
    display: block;
  }
  table thead {
    margin-right: 0px;
  }
  table tbody {
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  table tr {
    width: 100%;
    display: flex;
    border-bottom: 0.25px solid rgba(151, 151, 151, 0.5);
  }
  table tr td,
  table tr th {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    word-break: break-word;
    white-space: pre-wrap;
    overflow: hidden;
  }

  table table {
    height: 50%;
  }
}
.actions-transparent {
  color: transparent !important;
}

.table-wrapper table {
  td.td-short, th.td-short {
    flex-grow: 0.7;
  }
}
.candidate-td {
  flex-direction: column;
  padding: 0 !important;
  padding-bottom: 5px;
  table {
    width: calc(100% - 80px);
    thead {
      tr {
        background: white;
        th {
          color: black;
          font-weight: 600;
          padding: 5px;
        }
      }
    }
    tbody {
      td {
        padding: 5px;
      }
    }
  }
}
h5 {
  font-size: 16px;
  color: #8e8d8a;
  font-weight: 600;
  text-transform: uppercase;
}
.form-label {
  font-size: 13px;
  .material-icons {
    font-size: 13px;
  }
}
label {
  margin-bottom: 0.3rem;
}
.form-check {
  display: inline-block;
}
.font-13 {
  font-size: 13px;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #007bff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tr-main i:hover {
  cursor: pointer;
}
.tr-main:nth-child(even) {
  background: white;
}
.loader {
  position: fixed;
  background: white;
  width: 100%;
  height: calc(100% - 59px);
  top: 59px;
  z-index: 10000;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;

  &.is-fullheight {
    height: 100%;
    top: 0;
  }
}
.schedule-row {
  padding: 5px 0;
}
.react-datetime-picker__wrapper {
  border: 1px solid #e5e6e7 !important;
  color: #495057;
  padding: 6px 12px;
  border-radius: 4px;
}
.react-datetime-picker__wrapper input {
  color: #495057;
}
.job-details-row {
  font-size: 14px;
  white-space: pre-wrap;
  padding-bottom: 5px;
}
.job-details-row .span-label {
  font-weight: 900;
}
.h5-job-details {
  margin-bottom: 20px;
  color: #4a4a4a;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
}
.navbar {
  background: white !important;
  margin: 10px 30px;
  .navbar-collapse {
    height: 63px;
    .navbar-nav {
      height: 100%;
      display: flex;
    }
  }
}
.dropdown-menu {
  left: -3px;
  overflow-y: auto;
  max-height: calc(100vh - 64px);
}
.dropdown-toggle.nav-link {
  color: black;
}
.navbar-dark .navbar-nav .nav-link,
.nav-change-pass {
  color: black;
  font-size: 14px;
  border-right: 0;
  font-weight: 500;
  border-bottom: 1px solid lightgrey;
  align-items: center;
  display: flex;
  padding: 0 10px;
  &:hover {
    color: black;
  }
}
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: #F7931E;
  border-bottom-color: #F7931E;
  font-weight: 600;
}
.nav-logout {
  padding: 0;
}
.nav-logout i {
  padding: 5px 0 0 0;
  color: black;
}
.nav-logout:hover i {
  color: black;
}
.navbar-brand {
  img {
    width: 100px;
  }
}

// Button Customizations
.btn:not(.btn-custom) {
  border-radius: 30px;
}
.btn:not(.btn-custom):hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-primary {
  background: #45BF7C !important;
  border-color: rgba(127, 158, 141, 0.8) !important;
  color: white;
  font-size: 12px;
  text-transform: uppercase;
}
.btn-primary:hover {
  background: #26D072 !important;
  border-color: #7f9e8d !important;
}
.btn-danger {
  background: #abb0b5 !important;
  border-color: #abb0b5 !important;
  text-transform: uppercase;
}
.btn-danger:hover {
  background: #abb0b5 !important;
  border-color: #abb0b5 !important;
}
.btn-light {
  background: rgba(47, 91, 176, 0.7) !important;
  border-color: rgba(47, 91, 176, 0.7) !important;
  color: white;
}
.btn-light:hover {
  background: rgba(47, 91, 176, 0.7) !important;
  border-color: rgba(47, 91, 176, 0.7) !important;
  color: white;
}
.btn-light:focus {
  background: rgba(47, 91, 176, 0.7) !important;
  border-color: rgba(47, 91, 176, 0.7) !important;
  color: white;
}
.btn-light:visited {
  background: rgba(47, 91, 176, 0.7) !important;
  border-color: rgba(47, 91, 176, 0.7) !important;
  color: white;
}
.btn-success {
  background: #45BF7C !important;
  border-color: #7f9e8d !important;
}
.btn-success:hover {
  background: #26D072 !important;
  border-color: #7f9e8d !important;
}
.btn-info:hover {
  background: #17a2b8;
}
.btn-custom {
  color: #8ac5a4;
}
.btn-custom:hover {
  text-decoration: none;
  color: #7f9e8d;
}

.border-primary {
  border-color: #e98074 !important;
}
.filterableCard {
  transition: background-color 0.2s ease, color 0.2s ease;
  padding: 20px 10px;
  &.active {
    font-weight: 600;
    color: black;
  }
}
.filterableCard:hover {
  color: black;
  font-weight: 600;
}
.candidates-filter-cards {
  display: flex;
  width: 99%;
  background: #ebeef4;
  justify-content: center;
  border-radius: 30px;
}

// Fullscreen Modal
.modal-dialog {
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-content {
  background: white !important;
  height: 100%;
  border: 0;
}
.modal-body {
  overflow: auto;
}
.modal-header {
  border: 0;
}
.modal-footer {
  border: 0;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background: #6f9a83 !important;
  border-color: #6f9a83 !important;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  background: #abb0b5;
  border-color: #abb0b5;
}
.btn-group.btn-group-actions {
  flex-wrap: wrap;
  .btn-danger {
    background: #f15a5a !important;
    border-color: #f15a5a !important;
    color: white;
    text-transform: none !important;
  }
  .btn-primary {
    background: #8ac5a4 !important;
    border-color: #8ac5a4 !important;
    text-transform: none !important;
  }
  .btn {
    border-radius: 50% !important;
    padding: 5px;
    margin: 2px;
    margin-top: 5px;
    flex-grow: 0;
    margin-right: 11px;
    font-size: 10px;
    text-align: center;
  }
  .btn-custom {
    padding: 0;
    font-size: 20px;
  }
}
.btn:focus {
  box-shadow: none !important;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: transparent;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background: #7f9e8d !important;
  border-color: #7f9e8d !important;
  pointer-events: none;
  opacity: 0.6;
}
.navbar-dark .navbar-toggler {
  background: black;
}
.list-group-item.active {
  background: #e98074 !important;
  border-color: #e98074 !important;
}
select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /* and then whatever styles you want*/
  height: 30px;
  width: 100px;
  padding: 5px;
}
.nav-username {
  color: black;
  font-size: 12px;
  font-weight: 400;
  min-width: 120px;
}

tbody::-webkit-scrollbar {
  -webkit-appearance: none;
}

tbody::-webkit-scrollbar:vertical {
  width: 11px;
}

tbody::-webkit-scrollbar:horizontal {
  height: 11px;
}

tbody::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

tbody::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px;
}

.purchase-plans-container {
  display: flex;
  padding-top: 100px;
  justify-content: center;
  width: 100%;
}
.purchase-plan-box {
  display: flex;
  height: 250px;
  flex-direction: column;
  width: 300px;
  box-shadow: 0px 0px 10px rgba(102, 116, 143, 0.08);
  .plan-title {
    background: #66748f;
    color: white;
    padding: 20px;
  }
}
.plan-title {
  padding: 10px 0;
  border-bottom: 1px solid #d8c3a5;
  text-align: center;
  width: 100%;
  color: #656563;
  font-size: 16px;
  font-weight: 500;
}
.plan-content {
  text-align: center;
  padding: 10px;
  &:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.plan-content-wrapper {
  padding-top: 10px;
  font-size: 14px;
}
.required-field {
  color: red;
}
.pagination {
  height: 36px;
  margin: 15px 0px;
  padding: 0;
  display: inline-block;
}
.pager,
.pagination ul {
  margin-left: 0;
  *zoom: 1;
}
.pagination ul {
  padding: 0;
  display: inline-block;
  *display: inline;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.pagination li {
  display: inline;
}
.pagination a {
  float: left;
  padding: 0 12px;
  line-height: 34px;
  color: var(--pagination-number-color);
  text-decoration: none;
  border: none;
  border-left-width: 0;
  font-size: 13px;
}
.pagination li {
  &:nth-child(1) {
    a {
      border: 1px solid var(--default-border-color);
      margin-right: 5px;
    }
  }
  &:nth-child(2) {
    a {
      border: 1px solid var(--default-border-color);
    }
  }
  &:last-child {
    a {
      border: 1px solid var(--default-border-color);
    }
  }
  &:nth-last-child(2) {
    a {
      margin-right: 5px;
      border: 1px solid var(--default-border-color);
    }
  }
}
.pagination li.active {
  background: transparent;
  opacity: 1;
  font-weight: 600;
}
.pagination .active a {
  background: transparent !important;
  color: var(--default-header-color);
}
.pagination a:hover {
  background: transparent !important;
  color: var(--pagination-border-hover);
}
.pagination .active a {
  cursor: default;
}
.pagination .disabled a,
.pagination .disabled a:hover,
.pagination .disabled span {
  background-color: transparent;
  cursor: default;
}
.pagination li:first-child a,
.pagination li:first-child span {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.pagination li:last-child a {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.pagination-centered {
  text-align: center;
}
.pagination-right {
  text-align: right;
}

.font-weight-bold {
  font-weight: 800;
}
.loggedChangePassPage {
  .form {
    box-shadow: 0px 0px 10px rgba(102, 116, 143, 0.08);
  }
}
.Toastify__toast {
  padding: 40px;
  text-align: center;
}
.Toastify__toast--error {
  background: #da3535 !important;
  border: 1px solid #da3535;
  padding: 20px !important;
}
.Toastify__toast--success {
  background: #25c352 !important;
  border: 1px solid #25c352;
  padding: 20px !important;
}

.tooltip {
  opacity: 1;
  .tooltip-inner {
    background: white;
    color: #4a4a4a;
    box-shadow: 0px 0px 8px rgba(110, 118, 134, 0.2);
    opacity: 1;
  }
}
.noteRecruiterTooltip {
  .tooltip-inner {
    max-width: none;
  }
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: white;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-left-color: white;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-right-color: white;
}

.react-calendar {
  border: none !important;
  box-shadow: 0px 0px 8px rgba(110, 118, 134, 0.2);
}

.terms-body {
  overflow: auto;
}
.form-control-file {
  border: 1px solid transparent;
  padding: 5px;
  &.is-invalid {
    border-color: #dc3545;
  }
}
.hovering-td {
  &:hover {
    cursor: pointer;
    color: black;
    font-weight: 600;
  }
}
.jumbotron {
  height: 100%;
  margin: 0;
}
.trim-td-text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.wrap-text {
  white-space: pre-wrap;
}
.candidate-td h4 {
  font-size: 18px;
}
i.material-icons {
  font-size: 10px;
}
i.material-icons:first-child {
  padding-right: 5px;
}

.signup-alt-link {
  float: right;
  color: black;
}
.signup-alt-link:hover {
  color: #2d2d2d;
  text-decoration: none;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #3f9b01;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.job-desc-intro-box {
  background: white;
  box-shadow: 0px 4px 15px 3px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding-bottom: 30px;
}
.intro-box-earnings-fav {
  display: flex;
  padding: 30px;
  padding-bottom: 0px;
  .intro-box-buttons {
    margin-left: auto;
  }
  .btn {
    margin-right: 10px;
  }
}
.intro-box-recruiter-earnings {
  display: flex;
  align-items: center;
  text-align: right;
}
.intro-box-job-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 7.5px;
  padding-left: 30px;

  .intro-box-job-details-left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    p {
      margin: 2px;
    }
    .job-name {
      font-size: 1.5em;
      font-weight: 900;
    }
    .company-name {
      font-size: 1.1em;
      font-weight: 300;
    }
    .post-date {
      margin-top: 7.5px;
      font-size: 0.8em;
      font-weight: 200;
    }
  }

  .intro-box-job-details-right {
    text-align: right;
    padding-right: 30px;
    .num-of-pos-text {
      font-size: 1.1em;
      font-weight: 300;
      margin-bottom: 0;
    }
    .num-of-pos {
      font-size: 1.5em;
      font-weight: 900;
      margin-top: 0;
    }
  }
}
.job-desc-bottom-box {
  margin-top: 75px;
  margin-left: 100px;
  margin-right: 100px;
  hr {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
  }
}

.btn-job-listing {
  padding: 10px;
  margin: 6px;
  width: 99%;
  border-radius: 40px !important;
  border-color: rgb(175, 175, 175);

  .job-listing-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 50px;

    .job-listing-details-left {
      text-align: left;
    }
    .job-listing-details-right {
      display: flex;
      align-items: center;

      .earnings-postdate {
        text-align: right;
        margin-right: 20px;
      }
      .recruiter-earnings {
        font-size: 1.5em;
        font-weight: 900;
      }
    }
    p {
      margin: 6px;
    }
    .job-name {
      font-size: 1.5em;
      font-weight: 600;
    }
    .company-name {
      font-size: 1.2em;
      font-weight: 400;
    }
    .post-date {
      margin-top: 10px;
      font-size: 0.9em;
      font-weight: 300;
    }
  }
}

.signup-button-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.resume-feedback {
  min-height: 65.6px;
  margin: 40px 400px;
  padding: 20px;
  border: 1px solid black;
  border-radius: 30px;
  box-shadow: 0px 4px 15px 3px rgba(0, 0, 0, 0.25);
  text-align: center;
  white-space: pre-line;
}

@mixin can-toggle-branding(
  $can-toggle-off-color: #000dff,
  $can-toggle-on-color: #5fc054,
  $can-toggle-inactive-text: rgba(white, 0.5),
  $can-toggle-transition: cubic-bezier(0,1,0.5,1)
) {

  input[type="checkbox"] {

    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }

    &:focus ~ label, &:hover ~ label {
      .can-toggle__switch {
        background-color: $can-toggle-off-color;
        &:after { color: darken($can-toggle-off-color, 10%); }
      }
    }
    &:hover ~label { color: darken($can-toggle-off-color, 5%); }
    &:checked {
      ~ label {
        &:hover { color: darken($can-toggle-on-color, 3%); }

        .can-toggle__switch {
          background-color: lighten($can-toggle-on-color, 5%);
          &:after { color: darken($can-toggle-on-color, 5%); }
        }
      }

      &:focus, &:hover {
        ~ label {
          .can-toggle__switch {
            background-color: $can-toggle-on-color;
            &:after { color: darken($can-toggle-on-color, 10%); }
          }
        }
      }
    }
  }

  label {

    .can-toggle__label-text { flex: 1; }

    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: lighten($can-toggle-off-color, 5%);
      &:before { color: $can-toggle-inactive-text; }
      &:after {
        // Autoprefixer choked here, so making the prefixes explicit
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color;
      }
    }

  }
}

@mixin can-toggle-appearance
(
  $can-toggle-width: 134px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
){
  $can-toggle-switch-width: $can-toggle-width/2;

  input[type="checkbox"] {

    &:focus ~ label, &:hover ~ label {
      .can-toggle__switch {
        &:after { box-shadow: $can-toggle-shadow; }
      }
    }

    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after { transform: translate3d($can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset),0,0); }
        }
      }
      &:focus, &:hover {
        ~ label {
          .can-toggle__switch { &:after { box-shadow: $can-toggle-shadow; } }
        }
      }
    }
  }

  label {
    font-size: $can-toggle-label-font-size;

    .can-toggle__switch {
      height: $can-toggle-height;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;

      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size;
        line-height: $can-toggle-height;
        width: $can-toggle-width/2;
        padding: 0 12px;
      }

      &:after {
        top: $can-toggle-offset; left: $can-toggle-offset;
        border-radius: $can-toggle-border-radius/2;
        width: $can-toggle-switch-width - $can-toggle-offset;
        line-height: $can-toggle-height - ($can-toggle-offset*2);
        font-size: $can-toggle-switch-font-size;
      }

      &:hover {
        &:after { box-shadow: $can-toggle-shadow; }
      }
    }
  }
}

.can-toggle {
  position: relative;
  *, *:before, *:after { box-sizing: border-box; }
  //overflow: hidden;

  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0; left: 0;

    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch { opacity: 0.4; }
    }

    &:checked {
      ~ label {

        .can-toggle__switch {

          &:before {
            content: attr(data-unchecked);
            left: 0;
          }

          &:after {
            content: attr(data-checked);
          }
        }
      }

      // &:focus, &:hover {
      //   ~ label {
      //   }
      // }
    }
  }

  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;

    .can-toggle__label-text {
      flex: 1;
      padding-left: 32px;
    }

    .can-toggle__switch {
      position: relative;

      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }

      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: white;
        transform: translate3d(0,0,0);
      }

    }

  }



  // Default values for .can-toggle class
  @include can-toggle-branding;
  @include can-toggle-appearance;

  // Create toggles of different sizes by overriding the can-toggle-appearance mixin defaults
  &.can-toggle--size-small {
    @include can-toggle-appearance
    (
                    90px, // Toggle width
                    28px,  // Toggle height
                    2px,   // Toggle border radius
                    1px,   // Offset (distance btw switch and box)
                    13px,  // Label font size
                    10px,  // Switch font size
                    0 2px 2px rgba(black, 0.4) // Switch shadow on hover/focus
    );
  }
}

/**
 Resume Assessment
*/

.dot-red {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  float: left;
}

.dot-yellow {
  height: 25px;
  width: 25px;
  background-color: rgb(248, 236, 24);
  border-radius: 50%;
  float: left;
}

.dot-green {
  height: 25px;
  width: 25px;
  background-color: rgb(0, 204, 0);
  border-radius: 50%;
  float: left;
}

.dot-error {
  height: 25px;
  width: 25px;
  background-color: rgb(246, 84, 192);;
  border-radius: 50%;
  float: left;
}

.assessment-parsing-error {
  color: red;
}

.assessment-status {
  margin-bottom: 15px;
}

li.assessment-status {
  list-style-type: none;
}

.assessment-list-item {
  margin-bottom: 15px;
}

.assessment-file-name {
  font-weight: 600 !important;
}

.assessment-requirement-title {
  text-decoration: underline;
}

.assessment-feedback-title {
  text-decoration: underline;
}

.assessment-requirement {

}

.assessment-feedback-green {
  text-decoration: underline dotted rgb(0, 204, 0);
}

.assessment-feedback-yellow {
  text-decoration: underline dotted rgb(248, 236, 24);
}

.assessment-feedback-red {
  text-decoration: underline dotted red;
}

.assessment-loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: assessment-spin 2s linear infinite; /* Safari */
  animation: assessment-spin 2s linear infinite;

  &--small {
    @include size($w: 16px, $h: 16px);
    border-width: 3px;
    border-top-width: 3px;
  }
}

.list-header {
  padding-top: 15px;
}

.assessment-counter-label {
  font-size: 14px;
  font-weight: 400;
}

/* Safari */
@-webkit-keyframes assessment-spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes assessment-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* ======= Jobs page START =======  */
.jobs-page {
  padding-top: 25px;
  padding-bottom: 40px;
}

.jobs-sidebar {
  @include flex-column($gap: 20px);
}

.jobs-grid {
  background: #fff;
  border-radius: var(--border-radius-sm);
  overflow: auto;
  padding-bottom: 20px;
  border: 1px solid #A3A3A3;
}

.jobs-grid-header {
  @include flex-column($gap: 12px);
  padding: 20px;
  //background: #FFF9F4;

  &__title {
    color: #2C3033;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.2;
  }
}

.jobs-grid-body {
  padding: 12px 20px;
  overflow: auto;
  max-height: calc(100vh - 80px - 10px - 40px - 125px - 36px - 30px - 40px);
}

.pagination-grid {
  padding: 10px 10px 0 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;

  .pagination {
    margin: 0;
  }

  a {
    padding: 0;
    line-height: initial;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li.active a {
    background: #565555 !important;
    border-radius: 5px;
    color: #fff;
  }
}

.jobs-grid-row {
  display: grid;
  min-height: 72px;
  align-items: center;
  grid-template-columns: 200px 1fr 1fr 1fr 280px;
  column-gap: 20px;
  gap: 20px;
  padding: 14px 0;
  border: 1px solid transparent;
  border-bottom-color: #BBBDBE;
  position: relative;

  &--with-pin {
    grid-template-columns: 200px 1fr 1fr 1fr 340px;
  }

  &:first-of-type {
    padding-top: 0;
  }
}

.jobs-grid-сol {
  @include flex-column($gap: 2px);
}

.jobs-grid-сol-actions {
  @include flex-row($gap: 20px);
  @include flex-vertical;

  > * {
    flex-shrink: 0;
  }
}

.grid-text-title {
  @include font($fs: 18px, $fw: 700);
}

.grid-text-title-secondary {
  @include font($fs: 16px, $fw: 500);
}

.grid-text-sub {
  @include font($fs: 12px, $fw: 400);
  white-space: nowrap;
}

.grid-link {
  @include font($fs: 12px, $fw: 700, $c: #1A3BEA);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

.grid-text {
  @include font($fs: 12px, $fw: 300);

  &--bold {
    font-weight: 700;
  }
}

.btn-jobs-outline {
  background: transparent !important;
  border-radius: 10px !important;
  width: 100px;
  font-size: 16px;
}

.jobs-filter-bar {
  &__row {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  &__dropdowns {
    display: flex;
    gap: 20px;
  }
}

.filterbar-dropdown {
  .dropdown-toggle {
    background: var(--color-primary) !important;
    width: 228px;
    border-radius: 8px;
    border-color: transparent !important;
    box-shadow: none !important;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 16px;
    padding-block: 0;
    height: 32px;

    // caret
    &:after {
      content: '';
      display: block;
      margin: 0;
      width: 8px;
      height: 8px;
      border: 0;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
      position: absolute;
      top: 10px;
      right: 16px;
      transition: transform 0.2s;
    }
  }

  // active state
  .dropdown-toggle:not(:disabled):not(.disabled):active,
  &.show.dropdown .dropdown-toggle {
    background: #FFF1E1 !important;
    color: #1E1E1E;
  }

  // caret active state
  &.show.dropdown .dropdown-toggle::after {
    transform: rotate(-135deg);
    transform-origin: 5px 6px;
    border-color: #1E1E1E;
  }

  .dropdown-menu {
    width: 228px;
  }

  .dropdown-item {
    white-space: initial;
  }
}

// filterbar search
.fb-search {

  .input-group-text {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background: #fff;
    border-color: #A3A3A3;
    padding-right: 3px;
  }

  .form-control {
    border-color: #A3A3A3;
    border-left-color: transparent;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    font-size: 20px;
    font-weight: 300;

    &::placeholder {
      font-size: 20px;
      font-weight: 300;
      color: #A3A3A3;
    }
  }

  // modifiers
  &--radius {
    .input-group-text {
      border-top-left-radius: var(--border-radius-md);
      border-bottom-left-radius: var(--border-radius-md);
    }

    .form-control {
      border-top-right-radius: var(--border-radius-md);
      border-bottom-right-radius: var(--border-radius-md);
      padding-left: 4px;
    }
  }

  &--font {
    .form-control {
      @include font($fs: 12px, $fw: 300, $c: #737373);
    }

    .form-control::placeholder {
      @include font($fs: 12px, $fw: 300, $c: #737373);
    }
  }
}

// base card styles
.base-card {
  @include flex-center;
  padding: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius-md);
  transition: all 0.25s;
  cursor: pointer;

  // variant modifiers
  &--default {
    background: var(--color-primary);

    &:hover {
      background: var(--color-primary-darker);
    }
  }

  &--light {
    background: #fff;

    &:hover {
      background: #f6f5f5;
    }
  }

  // common content styles
  &__wrp {
    @include flex-column($gap: 10px);
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__icon {
    fill: transparent;
  }

  &__title {
    @include font($fs: 16px, $fw: 700, $lh: 1.2, $c: #fff);
    margin: 0;

    .base-card--light & {
      color: var(--color-primary);
    }
  }

  &__content {
    @include font($fs: 12px, $fw: 400, $lh: 1.2, $c: #fff);
    margin: 0;

    .base-card--light & {
      color: #000;
    }
  }
}
/* ======= Jobs page END =======  */

/* ======= Employer Candidates page START =======  */
.ec-sidebar {

  &__content {
    padding-top: 8px;
    @include flex-column($gap: 20px);
  }
}

.ec-content {
  padding-top: 10px;
}

.btn-back {
  font-size: 16px;
  font-weight: 500;
  color: #000 !important;

  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 0;
  border-color: transparent !important;
  background: transparent !important;
  box-shadow: none !important;
  transition: opacity 0.3s;

  &:hover, &:focus {
    opacity: 0.6;
  }

  &:active {
    top: 1px;
  }
}

// new custom button base styles
.button {
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0 16px;
  border: 1px solid transparent;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  transition: all 0.25s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:active:not(:disabled) {
    top: 1px;
  }

  &-primary {
    border-radius: 5px;
    color: var(--color-white);
    background: var(--color-primary);

    @include hover {
      background: var(--color-primary-darker);
    }

    @include focus {
      box-shadow: 0 0 0 4px rgba(255, 138, 0, 0.5);
      background: var(--color-primary);
    }
  }

  &-lg {
    height: 48px;
    padding: 0 25px;
    font-size: 18px;
    border-radius: var(--border-radius-md);
  }

  &--outline {
    background: transparent;
    border-radius: 10px;
    height: 38px;
    padding: 6px 12px;
    width: 100px;
    font-size: 16px;
    color: #343a40;
    border: 1px solid #343a40;
    @include flex-center;

    @include hover {
      background: darken(#fff, 10%);
    }

    @include focus {
      box-shadow: 0 0 0 2px darken(#fff, 30%);
    }

    @include active {
      top: 0;
      background: darken(#fff, 20%);
    }
  }

  &--transparent {
    @include flex-center;
    background: transparent;
    transition: all 0.3s;
    padding: 20px;

    @include hover {
      background: #cdcdcd;
    }

    @include focus {
      box-shadow: 0 0 0 2px #afafaf;
    }

    @include active {
      top: 0;
      background: darken(#cdcdcd, 10%);
    }
  }

  .icon-remove {
    flex-shrink: 0;
    fill: #5C5C5C;
  }
}

%button-pin {
  border-radius: 10px;
  height: 38px;
  padding: 6px;
  width: 40px;
  border: 1px solid #343a40;
  gap: 4px;
  font-size: 16px;
  @include flex-center;
}

.button-pin {
  @extend %button-pin;
  background: #2C2C2C;
  color: #F5F5F5;

  @include hover {
    background: lighten(#2C2C2C, 10%);
    opacity: 0.8;
  }

  @include focus {
    box-shadow: 0 0 0 2px lighten(#2C2C2C, 30%);
  }

  @include active {
    top: 0;
    background: darken(#2C2C2C, 20%);
  }
}

.button-unpin {
  @extend %button-pin;
  background: transparent;
  border: 1px solid #343a40;
  color: #343a40;

  @include hover {
    background: darken(#E3E3E3, 10%);
    opacity: 0.8;
  }

  @include focus {
    box-shadow: 0 0 0 2px darken(#E3E3E3, 30%);
  }

  @include active {
    top: 0;
    background: darken(#E3E3E3, 20%);
  }
}

.job-posting-description {
  @include flex-column($gap: 10px);
  min-height: 62px;

  &__title {
    @include font($fs: 20px, $fw: 500, $ls: -0.01em);

    margin: 0;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }
}

.job-posting-description-loading {
  @include flex-center;
  min-height: 62px;
}

.job-posting-description-error {
  @include flex-column($gap: 10px);
  min-height: 62px;
  text-align: center;
  @include font($fs: 16px, $fw: 400, $lh: 1.4, $c: var(--color-danger));
}

.acceptance-card {
  @include flex-column($gap: 10px);
  text-align: center;
  padding: var(--padding-card);
  border-radius: var(--border-radius-md);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: #fff;

  h3, p {
    margin: 0;
  }

  &__progress {
    @include size($w: 100px, $h: 100px);
  }

  &__content {
    @include flex-column($gap: 10px);
    @include flex-center;
  }

  &__description {
    @include flex-column($gap: 4px);
  }

  &__title {
    width: 100%;
    @include font($fs: 20px, $fw: 600);
  }

  &__rate {
    @include font($fs: 20px, $fw: 500, $lh: 1);
  }

  &__txt {
    @include font($fs: 16px);
  }

  &__sub {
    @include font($fs: 12px, $fw: 400, $c: var(--color-primary));
  }
}

.acceptance-card-loading {
  @include flex-center;
  min-height: 62px;
}

.acceptance-card-error {
  @include flex-column($gap: 10px);
  min-height: 180px;
  text-align: center;
  @include font($fs: 16px, $fw: 400, $lh: 1.4, $c: var(--color-danger));
}

.ec-grid {
  padding: var(--padding-card);
  border-radius: var(--border-radius-md);
  border: 1px solid #A3A3A3;
  background: var(--color-white);
}

.ec-grid-table-wrp {
  padding-top: 14px;
}

.ec-grid-table {
  width: 100%;
  overflow: auto;
}

.ec-grid-body {
  height: calc(100vh - 380px);
}

.ec-filterbar {
  @include flex-vertical;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  &__title {
    @include font($fs: 22px, $fw: 600);
    margin: 0;
  }

  &__filter {
    @include flex-row($gap: 10px, $align: center);
  }

  &__search {
    width: 200px;
  }

  &__status {
    width: 200px;
  }

  .react-select__value-container {
    padding: 5px 8px;
  }
}

.ec-grid-row {
  @include flex-row;
  min-height: 64px;
}

.ec-grid-col {
  border-top: 1px solid transparent;
  border-bottom: 1px solid #BBBDBE;
  flex-shrink: 0;
  @include flex-row($gap: 4px, $align: center);
  padding: 10px;
  @include font($fs: 16px, $fw: 400);
}

.ec-grid-row-header {
  position: sticky;
  top: -1px;
  z-index: 1;
  .ec-grid-col {
    font-weight: 500;
    background: #ffedd7;
  }
}

.status-dropdown {
  padding: 0;
  background: transparent;
  border: 0;
  transition: opacity 0.25s;
  @include hover {
    opacity: 0.7;
  }

  &::after {
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -2px
  }
}

// generate columns size by names
$columns: (
  name: 288px,
  date: 130px,
  resume: 100px,
  status: 180px,
  salary: 170px,
  envelope: 68px,
  score: 86px,
);

@each $name, $width in $columns {
  .ec-grid-col-#{$name} {
    width: $width;
  }
}

.ec-grid-col-name {
  line-height: 1.8;
}

.ec-grid-col-status {
  justify-content: center;
}

.ec-grid-col-salary,
.ec-grid-col-date {
  justify-content: flex-end;
}

.ec-link {
  @include font($fs: 16px, $fw: 400, $c: var(--color-primary)!important);
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.ec-download {
  padding: 10px;
  cursor: pointer;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.4;
  }
}

.ec-score {
  font-weight: 700;
  cursor: pointer;
}
/* ======= Employer Candidates page END =======  */

/* ======= Redesign modal START ======= */
.redesign-modal {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);

  &__dialog {
    width: fit-content;
    max-width: 1120px;
    height: auto;
    margin: 1.75rem auto;
  }

  &__content {
    padding: 30px 70px;

    @media screen and (max-width: 1200px) {
      padding: 30px;
    }
  }

  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    background: transparent;
    border: 0;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover, &:focus {
      opacity: 0.4;
    }
  }

}
/* Redesign modal END */

/* ======= Ai assessment modal START ======= */
.ai-assessment-layout {
  display: flex;
  align-items: flex-start;
  gap: 50px;

  @media screen and (max-width: 1140px) {
    flex-direction: column;
  }

  .feedback {
    width: 510px;
    @media screen and (max-width: 1140px) {
      width: 420px;
    }
  }
}
.assessment-upload {
  width: 420px;
  flex: 0 0 auto;

  &__title {
    @include font($fs: 36px, $fw: 600, $lh: 1);
    margin: 0;
  }

  &__sub {
    @include font($fs: 16px, $fw: 400, $c: #737373);
    margin: 4px 0 0;
  }

  &__row {
    margin: 24px 0 0;
  }

  &__body {
    padding: 24px 0;
    @include flex-column($gap: 30px);
  }

  &__footer {
    @include flex-row($gap: 20px, $align: center, $justify: center);
  }

  &__footer-2nd {
    margin: 24px 0 0;
    @include flex-row($gap: 20px, $align: center, $justify: center);
  }
}
/* ======= Ai assessment modal END ======= */

/* =======  Ai assessment modal content START =======  */
.drop-zone {
  height: 316px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed #eeeeee;
  border-radius: var(--border-radius-lg);
  background: #fff;
  outline: none;
  transition: border 0.24s ease-in-out;

  &__title {
    @include font($fs: 24px);
    margin: 30px 0 0;
  }

  &__sub {
    @include font($fs: 12px);
    margin: 4px 0 0;
  }
}

.uploaded-resumes {
  &__title {
    @include font($fs: 12px, $fw: 500);
    margin: 0;
    border-bottom: 1px solid #BBBDBE;
    padding: 5px 0;
    text-transform: capitalize;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
  }

  &__list-item {
    @include font($fs: 12px);
    @include flex-row($gap: 5px, $align: center);
    padding: 4px 0;

    &--empty-status {
      padding-left: 21px;
    }

    &--clickable {
      @include font($fs: 12px, $c: #0085D1);
      cursor: pointer;
      text-decoration: underline;
    }

    &--error {
      @include font($fs: 12px, $c: var(--color-danger));
    }
  }
}

.feedback {
  &__title {
    @include font($fs: 36px, $fw: 600, $lh: 1);
    margin: 0;
  }

  &__sub {
    @include font($fs: 16px, $fw: 400, $c: #737373);
    margin: 4px 0 0;
  }

  &__body {
    padding: 24px 0;
    @include flex-column($gap: 30px);
  }
}

.fb-error-v1 {
  @include font($fs: 16px, $fw: 400, $lh: 1.4, $c: var(--color-danger));

  &__title {
    margin: 0;
  }

  &__txt {
    margin: 4px 0 0;
  }
}

.fb-error-v2 {

  &__title {
    margin: 0;
    @include font($fs: 16px, $fw: 500, $lh: 1.4, $c: var(--color-danger));
  }

  &__txt {
    margin: 4px 0 0;
    color: #000;
  }

  &__link {
    @include font($fs: 14px, $fw: 500, $c: #1A3BEA);
    text-decoration: underline;
    margin: 10px 0 0;
    display: inline-flex;
    &:hover {
      text-decoration: none;
    }
  }
}

.error-txt {
  margin: 0;
  @include font($fs: 16px, $fw: 500, $lh: 1.4, $c: var(--color-danger));
}

.assessment-result {

  &__top {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .assessment-result--scroll & {
      position: sticky;
      top: 0;
      background: #fff;
    }

    span {
      padding: 5px 10px;
      @include font($fs: 18px, $fw: 600);
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 10px 0 0;

    &--dropdown {
      max-height: 250px;
      overflow: auto;
      margin: 0;
      .assessment-result__item {
        grid-template-columns: 1fr;
        padding: 5px 0;
        &:nth-child(odd) {
          background: #fff;
        }
      }
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 0;
    min-height: 60px;
    @include font($fs: 12px);

    &:nth-child(odd) {
      background: #fff7ee;
    }
  }

  &__status {
    border-radius: 50%;
    display: block;
    flex: 0 0 auto;
    @include size($w: 20px, $h: 20px);
  }

  &__requirement {
    padding-inline: 10px;
  }

  &__feedback {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-inline: 10px;
  }

  &--scroll {
    max-height: 350px;
    overflow: auto;
  }
}

.assessment-score {
  @include flex-column($gap: 4px);

  &__row {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      @include font($fs: 18px, $fw: 600);
    }
  }

  &__title {
    @include font($fs: 18px, $fw: 600);
    margin: 0;
  }

  &__progress {
    flex: 1;
  }
}

.assessment-tab-wrp {
  @include flex-column($gap: 15px);
}

.assessment-tab {
  &__title {
    @include font($fs: 24px, $fw: 600);
    margin: 0;
  }

  &__txt {
    @include font($fs: 14px);
    margin: 0;

    &--margin {
      margin: 10px 0 0;
    }
  }
}

.feedback-contacts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  gap: 20px;
}

.feedback-details {
  @include flex-column($gap: 5px);

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    gap: 20px;
  }

  &__title {
    @include font($fs: 18px, $fw: 600);
  }
}

.fb-item {
  &__label {
    @include font($fs: 12px, $fw: 600);
  }

  &__value {
    @include font($fs: 16px);
  }
}
/* =======  Ai assessment modal content END =======  */

/* =======  Popover assessment START =======  */
.popover-assessment {
  width: 350px;
  background: #fff;
  border-radius: var(--border-radius-md);
  border: 1px solid #CECCCC;
  position: relative;
  z-index: 2;

  .popover-header {
    padding-inline: 22px;
    background: transparent;
  }
}
/* =======  Popover assessment END =======  */

/* =======  Remove job post modal START =======  */
.remove-job-modal {
  &__title {
    @include font($fs: 20px, $fw: 600, $lh: 1.6);
    margin: 0;
    position: relative;

    .icon-remove {
      position: absolute;
      left: -24px;
      top: 8px;
    }
  }

  &__txt {
    @include font($fs: 16px);
    margin: 0;
  }

  &__actions {
    @include flex-row($gap: 20px);
    margin: 14px 0 0;
  }

  // TODO: need create new types of button for accept/cancel
  %button-placeholder {
    transition: all 0.25s;
    height: 38px;
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    padding-inline: 16px;
    border: 0;
  }
  .button-accept {
    @extend %button-placeholder;
    background: #2C2C2C;

    @include font($fs: 16px, $c: #F5F5F5);

    &:hover {
      opacity: 0.8;
    }
  }
  .button-cancel {
    @extend %button-placeholder;
    background: #E3E3E3;

    @include font($fs: 16px);

    &:hover {
      opacity: 0.8;
    }
  }
}
/* =======  Remove job post modal END =======  */
