* { scroll-behavior: smooth; }

:root {
  --bg-color: rgba(247, 147, 30, 0.05);
  --rnr-orange: rgb(247, 147, 30);
}

.outer-container {
  background-color: var(--bg-color);
  color: #222222;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 70px 150px;
}

.login-button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  font-weight: 700;
}
.login-button:hover {
  color: var(--rnr-orange);
  transition: all 0.3s ease-in-out;
}

.title-container {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  max-width: 1350px;
}

.h1-title {
  text-align: center;
  font-size: 60px;
  margin: 0px 40px;
  .text-orange {
    color: var(--rnr-orange);
  }
}

.sub-title {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin: 40px 0px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.sign-up-button {
  background-color: var(--rnr-orange);
  color: white;
  border: none;
  border-radius: 20px;
  width: 150px;
  height: 50px;
}
.sign-up-button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.watch-demo-button {
  background-color: transparent;
  color: var(--rnr-orange);
  border: 3px solid var(--rnr-orange);
  border-radius: 20px;
  width: 150px;
  height: 50px;
}
.watch-demo-button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.companies-container {
  margin: 70px 70px;
}

.companies-text {
  text-align: center;
  margin-bottom: 40px;
}

.companies-logos {
  display: flex;
  justify-content: space-around;
  .logos {
    height: 30px;
  }
}

.demo-video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.demo-video-text {
  font-size: 25px;
  font-weight: 600;
  margin-top: 40px;
}

.demo-video {
  width: 893px;
  height: 504px;
  margin: 60px;
  border: 3px solid var(--rnr-orange);
  border-radius: 30px;
}  

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0px;
}

.login-text {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 40px;
}

.form-container {
  width: 25%;
  .form-group {
    width: 100%;
    margin-bottom: 2em;
  }
  .input-group {
    flex-direction: column;
  }
  .form-label {
    font-size: 15px;
    margin-bottom: 0.6em;
  }
  .input-group > .form-control {
    width: 100%;
  }
}

.btns-wrapper-login {
  display: flex;
  align-items: center;
  .btn-primary {
    margin-left: auto;
    font-size: 16px;
  }
}
